/**
 * Generated by orval v6.26.0 🍺
 * Do not edit manually.
 * UW Agency API
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AgencyGetAgenciesByAgencyUrlResult,
  AgencyGetAgencyByIdResult,
  AgencyGetApprovalStatusResult,
  AgencyListAgenciesAgencyList,
  AgencyListApprovalsApprovalList,
  AgencyRegisterAgencyResult,
  AgencyUpdateActiveStatusForm,
  AgencyUpdateAgencyResult,
  AgencyUpdateApprovalStatusForm,
  AgencyUpdateApprovalStatusResult,
  ExportAgenciesParams,
  ExportContactsParams,
  ListAgenciesParams,
  ListApprovalsParams,
  RegisterBody,
  UpdateAgencyBody,
} from '.././models';
import { apiRequest } from '.././client';
import { uploadFormData } from '.././upload';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const listAgencies = (
  params?: ListAgenciesParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<AgencyListAgenciesAgencyList>(
    { url: `/Agency`, method: 'GET', params, signal },
    options
  );
};

export const getListAgenciesQueryKey = (params?: ListAgenciesParams) => {
  return [`/Agency`, ...(params ? [params] : [])] as const;
};

export const getListAgenciesQueryOptions = <
  TData = Awaited<ReturnType<typeof listAgencies>>,
  TError = unknown
>(
  params?: ListAgenciesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listAgencies>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAgenciesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAgencies>>> = ({ signal }) =>
    listAgencies(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAgencies>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAgenciesQueryResult = NonNullable<Awaited<ReturnType<typeof listAgencies>>>;
export type ListAgenciesQueryError = unknown;

export const useListAgencies = <TData = Awaited<ReturnType<typeof listAgencies>>, TError = unknown>(
  params?: ListAgenciesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listAgencies>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAgenciesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAgencyById = (
  id: string,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<AgencyGetAgencyByIdResult>(
    { url: `/Agency/${id}`, method: 'GET', signal },
    options
  );
};

export const getGetAgencyByIdQueryKey = (id: string) => {
  return [`/Agency/${id}`] as const;
};

export const getGetAgencyByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getAgencyById>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgencyById>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAgencyByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgencyById>>> = ({ signal }) =>
    getAgencyById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAgencyById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAgencyByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getAgencyById>>>;
export type GetAgencyByIdQueryError = unknown;

export const useGetAgencyById = <
  TData = Awaited<ReturnType<typeof getAgencyById>>,
  TError = unknown
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAgencyById>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAgencyByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateAgency = (
  id: string,
  updateAgencyBody: UpdateAgencyBody,
  options?: SecondParameter<typeof apiRequest>
) => {
  const formData = uploadFormData(updateAgencyBody);
  return apiRequest<AgencyUpdateAgencyResult>(
    {
      url: `/Agency/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getUpdateAgencyMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAgency>>,
    TError,
    { id: string; data: UpdateAgencyBody },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAgency>>,
  TError,
  { id: string; data: UpdateAgencyBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAgency>>,
    { id: string; data: UpdateAgencyBody }
  > = props => {
    const { id, data } = props ?? {};

    return updateAgency(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAgencyMutationResult = NonNullable<Awaited<ReturnType<typeof updateAgency>>>;
export type UpdateAgencyMutationBody = UpdateAgencyBody;
export type UpdateAgencyMutationError = unknown;

export const useUpdateAgency = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAgency>>,
    TError,
    { id: string; data: UpdateAgencyBody },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateAgency>>,
  TError,
  { id: string; data: UpdateAgencyBody },
  TContext
> => {
  const mutationOptions = getUpdateAgencyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteAgency = (id: string, options?: SecondParameter<typeof apiRequest>) => {
  return apiRequest<void>({ url: `/Agency/${id}`, method: 'DELETE' }, options);
};

export const getDeleteAgencyMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAgency>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAgency>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAgency>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteAgency(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAgencyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAgency>>>;

export type DeleteAgencyMutationError = unknown;

export const useDeleteAgency = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAgency>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAgency>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteAgencyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const register = (
  registerBody: RegisterBody,
  options?: SecondParameter<typeof apiRequest>
) => {
  const formData = uploadFormData(registerBody);
  return apiRequest<AgencyRegisterAgencyResult>(
    {
      url: `/Agency/Register`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  );
};

export const getRegisterMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof register>>,
    TError,
    { data: RegisterBody },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof register>>,
  TError,
  { data: RegisterBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof register>>,
    { data: RegisterBody }
  > = props => {
    const { data } = props ?? {};

    return register(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterMutationResult = NonNullable<Awaited<ReturnType<typeof register>>>;
export type RegisterMutationBody = RegisterBody;
export type RegisterMutationError = unknown;

export const useRegister = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof register>>,
    TError,
    { data: RegisterBody },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof register>>,
  TError,
  { data: RegisterBody },
  TContext
> => {
  const mutationOptions = getRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAgenciesByVanityUrl = (
  vanityUrl: string,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<AgencyGetAgenciesByAgencyUrlResult>(
    { url: `/Agency/ByVanityUrl/${vanityUrl}`, method: 'GET', signal },
    options
  );
};

export const getGetAgenciesByVanityUrlQueryKey = (vanityUrl: string) => {
  return [`/Agency/ByVanityUrl/${vanityUrl}`] as const;
};

export const getGetAgenciesByVanityUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getAgenciesByVanityUrl>>,
  TError = unknown
>(
  vanityUrl: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAgenciesByVanityUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAgenciesByVanityUrlQueryKey(vanityUrl);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAgenciesByVanityUrl>>> = ({ signal }) =>
    getAgenciesByVanityUrl(vanityUrl, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!vanityUrl, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAgenciesByVanityUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAgenciesByVanityUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAgenciesByVanityUrl>>
>;
export type GetAgenciesByVanityUrlQueryError = unknown;

export const useGetAgenciesByVanityUrl = <
  TData = Awaited<ReturnType<typeof getAgenciesByVanityUrl>>,
  TError = unknown
>(
  vanityUrl: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getAgenciesByVanityUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAgenciesByVanityUrlQueryOptions(vanityUrl, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateApprovalStatus = (
  agencyUpdateApprovalStatusForm: AgencyUpdateApprovalStatusForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<AgencyUpdateApprovalStatusResult>(
    {
      url: `/Agency/UpdateApprovalStatus`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: agencyUpdateApprovalStatusForm,
    },
    options
  );
};

export const getUpdateApprovalStatusMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateApprovalStatus>>,
    TError,
    { data: AgencyUpdateApprovalStatusForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateApprovalStatus>>,
  TError,
  { data: AgencyUpdateApprovalStatusForm },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateApprovalStatus>>,
    { data: AgencyUpdateApprovalStatusForm }
  > = props => {
    const { data } = props ?? {};

    return updateApprovalStatus(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateApprovalStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateApprovalStatus>>
>;
export type UpdateApprovalStatusMutationBody = AgencyUpdateApprovalStatusForm;
export type UpdateApprovalStatusMutationError = unknown;

export const useUpdateApprovalStatus = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateApprovalStatus>>,
    TError,
    { data: AgencyUpdateApprovalStatusForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateApprovalStatus>>,
  TError,
  { data: AgencyUpdateApprovalStatusForm },
  TContext
> => {
  const mutationOptions = getUpdateApprovalStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApprovalStatus = (
  agencyId: string,
  eventId: string,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<AgencyGetApprovalStatusResult>(
    { url: `/Agency/${agencyId}/ApprovalStatus/${eventId}`, method: 'GET', signal },
    options
  );
};

export const getGetApprovalStatusQueryKey = (agencyId: string, eventId: string) => {
  return [`/Agency/${agencyId}/ApprovalStatus/${eventId}`] as const;
};

export const getGetApprovalStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getApprovalStatus>>,
  TError = unknown
>(
  agencyId: string,
  eventId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApprovalStatus>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApprovalStatusQueryKey(agencyId, eventId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApprovalStatus>>> = ({ signal }) =>
    getApprovalStatus(agencyId, eventId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(agencyId && eventId),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getApprovalStatus>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetApprovalStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApprovalStatus>>
>;
export type GetApprovalStatusQueryError = unknown;

export const useGetApprovalStatus = <
  TData = Awaited<ReturnType<typeof getApprovalStatus>>,
  TError = unknown
>(
  agencyId: string,
  eventId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getApprovalStatus>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApprovalStatusQueryOptions(agencyId, eventId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateActiveStatus = (
  agencyUpdateActiveStatusForm: AgencyUpdateActiveStatusForm,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<void>(
    {
      url: `/Agency/UpdateActiveStatus`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: agencyUpdateActiveStatusForm,
    },
    options
  );
};

export const getUpdateActiveStatusMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateActiveStatus>>,
    TError,
    { data: AgencyUpdateActiveStatusForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateActiveStatus>>,
  TError,
  { data: AgencyUpdateActiveStatusForm },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateActiveStatus>>,
    { data: AgencyUpdateActiveStatusForm }
  > = props => {
    const { data } = props ?? {};

    return updateActiveStatus(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateActiveStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateActiveStatus>>
>;
export type UpdateActiveStatusMutationBody = AgencyUpdateActiveStatusForm;
export type UpdateActiveStatusMutationError = unknown;

export const useUpdateActiveStatus = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateActiveStatus>>,
    TError,
    { data: AgencyUpdateActiveStatusForm },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateActiveStatus>>,
  TError,
  { data: AgencyUpdateActiveStatusForm },
  TContext
> => {
  const mutationOptions = getUpdateActiveStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listApprovals = (
  id: string,
  params?: ListApprovalsParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<AgencyListApprovalsApprovalList>(
    { url: `/Agency/${id}/ListApprovals`, method: 'GET', params, signal },
    options
  );
};

export const getListApprovalsQueryKey = (id: string, params?: ListApprovalsParams) => {
  return [`/Agency/${id}/ListApprovals`, ...(params ? [params] : [])] as const;
};

export const getListApprovalsQueryOptions = <
  TData = Awaited<ReturnType<typeof listApprovals>>,
  TError = unknown
>(
  id: string,
  params?: ListApprovalsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listApprovals>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListApprovalsQueryKey(id, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listApprovals>>> = ({ signal }) =>
    listApprovals(id, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listApprovals>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListApprovalsQueryResult = NonNullable<Awaited<ReturnType<typeof listApprovals>>>;
export type ListApprovalsQueryError = unknown;

export const useListApprovals = <
  TData = Awaited<ReturnType<typeof listApprovals>>,
  TError = unknown
>(
  id: string,
  params?: ListApprovalsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof listApprovals>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListApprovalsQueryOptions(id, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const exportAgencies = (
  params?: ExportAgenciesParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<Blob>(
    { url: `/Agency/Export`, method: 'GET', params, responseType: 'blob', signal },
    options
  );
};

export const getExportAgenciesQueryKey = (params?: ExportAgenciesParams) => {
  return [`/Agency/Export`, ...(params ? [params] : [])] as const;
};

export const getExportAgenciesQueryOptions = <
  TData = Awaited<ReturnType<typeof exportAgencies>>,
  TError = unknown
>(
  params?: ExportAgenciesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof exportAgencies>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExportAgenciesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exportAgencies>>> = ({ signal }) =>
    exportAgencies(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exportAgencies>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExportAgenciesQueryResult = NonNullable<Awaited<ReturnType<typeof exportAgencies>>>;
export type ExportAgenciesQueryError = unknown;

export const useExportAgencies = <
  TData = Awaited<ReturnType<typeof exportAgencies>>,
  TError = unknown
>(
  params?: ExportAgenciesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof exportAgencies>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExportAgenciesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const exportContacts = (
  params?: ExportContactsParams,
  options?: SecondParameter<typeof apiRequest>,
  signal?: AbortSignal
) => {
  return apiRequest<Blob>(
    { url: `/Agency/ExportContacts`, method: 'GET', params, responseType: 'blob', signal },
    options
  );
};

export const getExportContactsQueryKey = (params?: ExportContactsParams) => {
  return [`/Agency/ExportContacts`, ...(params ? [params] : [])] as const;
};

export const getExportContactsQueryOptions = <
  TData = Awaited<ReturnType<typeof exportContacts>>,
  TError = unknown
>(
  params?: ExportContactsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof exportContacts>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExportContactsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof exportContacts>>> = ({ signal }) =>
    exportContacts(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof exportContacts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExportContactsQueryResult = NonNullable<Awaited<ReturnType<typeof exportContacts>>>;
export type ExportContactsQueryError = unknown;

export const useExportContacts = <
  TData = Awaited<ReturnType<typeof exportContacts>>,
  TError = unknown
>(
  params?: ExportContactsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof exportContacts>>, TError, TData>>;
    request?: SecondParameter<typeof apiRequest>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExportContactsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const applyForEvent = (
  agencyId: string,
  eventId: string,
  options?: SecondParameter<typeof apiRequest>
) => {
  return apiRequest<void>(
    { url: `/Agency/${agencyId}/ApplyForEvent/${eventId}`, method: 'POST' },
    options
  );
};

export const getApplyForEventMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof applyForEvent>>,
    TError,
    { agencyId: string; eventId: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof applyForEvent>>,
  TError,
  { agencyId: string; eventId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof applyForEvent>>,
    { agencyId: string; eventId: string }
  > = props => {
    const { agencyId, eventId } = props ?? {};

    return applyForEvent(agencyId, eventId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApplyForEventMutationResult = NonNullable<Awaited<ReturnType<typeof applyForEvent>>>;

export type ApplyForEventMutationError = unknown;

export const useApplyForEvent = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof applyForEvent>>,
    TError,
    { agencyId: string; eventId: string },
    TContext
  >;
  request?: SecondParameter<typeof apiRequest>;
}): UseMutationResult<
  Awaited<ReturnType<typeof applyForEvent>>,
  TError,
  { agencyId: string; eventId: string },
  TContext
> => {
  const mutationOptions = getApplyForEventMutationOptions(options);

  return useMutation(mutationOptions);
};
