import { GridColDef, GridPaginationModel, GridSortItem } from '@mui/x-data-grid';
import {
  DonationsDonationQuerySortProperty,
  ModelsAdminDonationModel,
  ModelsAgencyDonationModel,
} from '../../api/agency-api';
import { Grid } from '@mui/material';
import { TextInput } from '../../shared/forms';
import { formatTimestamp } from '../../shared/utils/formatTimestamp';
import { CurrencyMask } from '../../shared/forms/fields/InputMasks';

function getDonorColumnValue(row: ModelsAgencyDonationModel) {
  return row.donor?.isAnonymous && !row.donor?.personId
    ? 'Anonymous'
    : `${row.donor?.name?.first} ${row.donor?.name?.last}`;
}

export const agencyColumns: GridColDef<ModelsAgencyDonationModel>[] = [
  {
    field: 'donor',
    headerName: 'Donor',
    flex: 1,
    editable: false,
    valueGetter: ({ row }) => getDonorColumnValue(row),
    disableColumnMenu: true,
  },
  {
    field: 'totalAmount',
    headerName: 'Amount',
    flex: 1,
    editable: false,
    valueGetter: ({ row }) => `$${row.totalAmount?.toFixed(2)}`,
    disableColumnMenu: true,
  },
  {
    field: 'localDonationDate',
    headerName: 'Order Date',
    flex: 1,
    editable: false,
    valueGetter: ({ row }) => formatTimestamp(row.localDonationDate),
    disableColumnMenu: true,
  },
];

export const adminColumns: GridColDef<ModelsAdminDonationModel>[] = [
  {
    field: 'donatelyId',
    headerName: 'Donately Id',
    flex: 1,
    editable: false,
    disableColumnMenu: true,
  },
  {
    field: 'transactionId',
    headerName: 'Transaction Id',
    flex: 1,
    editable: false,
    disableColumnMenu: true,
  },
  {
    field: 'agencyName',
    headerName: 'Agency',
    flex: 1,
    editable: false,
    disableColumnMenu: true,
  },
  ...agencyColumns,
];
export const filterFields = (
  <>
    <Grid item xs={12} sm={4} md={3}>
      <TextInput
        name="minDonationAmt"
        variant="outlined"
        label="Min Donation Amount"
        InputProps={{ inputComponent: CurrencyMask as any }}
      />
    </Grid>
    <Grid item xs={12} sm={4} md={3}>
      <TextInput
        name="maxDonationAmt"
        variant="outlined"
        label="Max Donation Amount"
        InputProps={{ inputComponent: CurrencyMask as any }}
      />
    </Grid>
  </>
);

export interface DonationFilterForm {
  search?: string;
  eventId: string;
  minDonationAmt?: number;
  maxDonationAmt?: number;

  pagination: GridPaginationModel;
  orderBy: GridSortItem[];
}

export const sortFieldMap = new Map<string, DonationsDonationQuerySortProperty>([
  ['localDonationDate', DonationsDonationQuerySortProperty.Time],
  ['totalAmount', DonationsDonationQuerySortProperty.Amount],
]);
