import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { AuthenticationProvider, NextEventProvider, QueryProvider } from './providers';
import { CartProvider } from './cart/CartProvider';
import { theme } from './shared/theme';
import { NavigationStructureProvider } from './shared/routing';
import { AppRoutes } from './AppRoutes';
import TagManager from '@sooro-io/react-gtm-module';

import '@fontsource/palanquin/300.css';
import '@fontsource/palanquin/400.css';
import '@fontsource/palanquin/500.css';
import '@fontsource/palanquin/700.css';
import '@fontsource/antonio/700.css';

const tagManagerArgs = {
  gtmId: 'GTM-KR48L3K',
};

TagManager.initialize(tagManagerArgs);

const APPLICATION_TZ = 'America/New_York';

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(APPLICATION_TZ);

export const App: React.FC = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={5000}>
        <QueryProvider>
          <AuthenticationProvider>
            <NavigationStructureProvider>
              <NextEventProvider>
                <CartProvider>
                  <AppRoutes />
                </CartProvider>
              </NextEventProvider>
            </NavigationStructureProvider>
          </AuthenticationProvider>
        </QueryProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </LocalizationProvider>
);

export default App;
