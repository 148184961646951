import { Grid, Typography } from '@mui/material';
import { TextInput } from '../../shared/forms';
import { PhoneNumberMask } from '../../shared/forms/fields/InputMasks';

export default function AgencyContacts() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" component="p" color="primary.main">
              Contact 1
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput name="primaryContact.title" variant="outlined" label="Contact 1 Job Title" />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              name="primaryContact.name.first"
              label="Contact 1 First Name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              name="primaryContact.name.last"
              label="Contact 1 Last Name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              name="primaryContact.phoneNumber"
              label="Contact 1 Phone"
              InputProps={{ inputComponent: PhoneNumberMask as any }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              name="primaryContact.emailAddress"
              label="Contact 1 Email"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" component="p" color="primary.main">
              Contact 2 (Optional)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="secondaryContact.title"
              variant="outlined"
              label="Contact 2 Job Title"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="secondaryContact.name.first"
              variant="outlined"
              label="Contact 2 First Name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="secondaryContact.name.last"
              variant="outlined"
              label="Contact 2 Last Name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="secondaryContact.phoneNumber"
              variant="outlined"
              label="Contact 2 Phone"
              InputProps={{ inputComponent: PhoneNumberMask as any }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="secondaryContact.emailAddress"
              variant="outlined"
              label="Contact 2 Email"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
