import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { DashboardBanner, Link } from '../../shared/components';
import {
  AgencyCategory,
  useGetAgencyById,
  useGetApprovalStatus,
  useListEvents,
  useUpdateAgency,
} from '../../api/agency-api';
import { agencySchema } from '../forms/ValidationSchemas';
import { Form } from '../../shared/forms';
import AgencyContacts from './AgencyContacts';
import AgencyInfo from './AgencyInfo';
import { DropDownItem } from '../../shared/forms/fields/DropDownSelect';
import AgencySocialMedia from './AgencySocialMedia';
import AgencyAccounting from './AgencyAccounting';
import ImagePreview from '../../shared/components/ImagePreview';
import FileUpload from '../../shared/forms/fields/FileUpload';
import AgencyApprovalHistory from './AgencyApprovalHistory';
import { useQueryClient } from '@tanstack/react-query';
import AgencyActivateButton from '../../admin/components/AgencyActivateButton';
import { formatError } from '../../shared/utils/formatError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../../shared/icons';
import { EventSubmissionAlert } from '../../shared/components/EventSubmissionAlert';
import { useNextEvent } from '../../shared/events';
import { AgencyDonationsList } from './AgencyDonationsList';

const StatusBox = styled(TextField)(({ theme }) => ({
  '& label': {
    color: theme.palette.common.white,
    '&.Mui-disabled': {
      color: theme.palette.common.white,
      textFillColor: theme.palette.common.white,
    },
  },
  '& input': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    '&:disabled': {
      color: theme.palette.common.white,
      textFillColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.light,
    },
  },

  '& fieldset': {
    borderColor: theme.palette.common.white,
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    '& fieldset': {
      borderColor: theme.palette.common.white,
    },
  },
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.common.white,
  width: '100% !important',
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      sx={{ width: ['100%', '100%', '80%'] }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface Props {
  agencyId: string;
  isAdmin: boolean;
}

export const EditAgencyForm: React.FC<Props> = ({ agencyId, isAdmin }: Props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [tabIndex, setTabIndex] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, index: number) => {
    setTabIndex(index);
  };
  const nextEvent = useNextEvent();
  const { data: events } = useListEvents({ includePastEvents: true, donationAccessEnabled: true });

  type UpdateAgencyFields = yup.InferType<typeof agencySchema>;
  const [countiesServed, setCountiesServed] = useState<string[]>([]);
  const [logoFile, setLogoFile] = useState<File>();
  const [filePreview, setFilePreview] = useState<string>('#');
  const { data: agencyDetails, isFetched, queryKey } = useGetAgencyById(agencyId ?? '');
  const { data: nextEventApproval } = useGetApprovalStatus(agencyId ?? '', nextEvent?.id ?? '');
  const queryClient = useQueryClient();

  const { mutate: updateAgency } = useUpdateAgency({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar('Update successful', { variant: 'success' });
        queryClient.invalidateQueries({ queryKey: queryKey });
      },
      onError: (error: Error) => {
        const { message } = formatError(error);
        enqueueSnackbar(`${message}`, { variant: 'error' });
      },
    },
  });

  // set form default values and other form vals
  // after the data is fetched to fill the inputs
  useEffect(() => {
    if (isFetched && agencyDetails?.agency) {
      setCountiesServed(agencyDetails.agency.counties);
      setFilePreview(agencyDetails.agency?.logoUrl ?? '');
    }
  }, [isFetched, agencyDetails, agencyDetails?.agency]);

  const onFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setLogoFile(event.target.files[0]);
        setFilePreview(URL.createObjectURL(event.target.files[0]));
      }
    },
    [setLogoFile, setFilePreview]
  );

  return (
    <Box>
      {isFetched && agencyDetails && (
        <Form<UpdateAgencyFields>
          onSubmit={({ vanityUrl, ...vals }) => {
            updateAgency({
              id: agencyId ?? '',
              data: {
                ...vals,
                vanityUrl: vanityUrl?.trim(),
                counties: countiesServed,
                logoFile: logoFile,
              } as any,
            });
          }}
          resolver={yupResolver(agencySchema)}
          defaultValues={
            {
              ...agencyDetails.agency,
            } as any
          }
          method="put"
        >
          <DashboardBanner
            bannerBGColor="primary.light"
            align="end"
            justify="space-between"
            padding={2}
          >
            <Stack>
              {isAdmin && (
                <Link
                  to={'/admin/dashboard'}
                  sx={{ fontSize: '.75rem', color: 'common.white', fontWeight: '400' }}
                >
                  <FontAwesomeIcon icon={icons.back} /> Back to dashboard
                </Link>
              )}
              <Typography variant="h5" component="h1" sx={{ color: 'common.white' }}>
                {agencyDetails?.agency?.displayName}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" width="50%">
              <StatusBox
                name=""
                label="Next Event"
                variant="outlined"
                disabled
                value={nextEvent?.name}
              >
                <Typography></Typography>
              </StatusBox>
              <StatusBox
                name=""
                label="Status"
                variant="outlined"
                disabled
                value={nextEventApproval?.approvalStatus ?? ''}
              ></StatusBox>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button type="submit" variant="outlined" sx={{ padding: '.75rem 1rem' }}>
                Save
              </Button>
              {isAdmin && (
                <AgencyActivateButton
                  agencyId={agencyDetails.agency?.id ?? ''}
                  isActive={!agencyDetails.agency?.deactivatedDate}
                  sx={{ padding: '.75rem 1rem' }}
                />
              )}
            </Stack>
          </DashboardBanner>
          <Container maxWidth="xl" sx={{ display: 'flex' }}>
            <Stack direction={{ xs: 'column', md: 'row' }} sx={{ width: '100%' }}>
              <Tabs
                orientation={isSmallScreen ? 'horizontal' : 'vertical'}
                variant="scrollable"
                scrollButtons
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                <Tab
                  label="Agency Information"
                  {...a11yProps(0)}
                  sx={{ alignItems: 'start', '&.Mui-selected': { backgroundColor: 'grey.200' } }}
                />
                <Tab
                  label="Approval Log"
                  {...a11yProps(1)}
                  sx={{ alignItems: 'start', '&.Mui-selected': { backgroundColor: 'grey.200' } }}
                />
                <Tab
                  label="Donations"
                  {...a11yProps(2)}
                  sx={{ alignItems: 'start', '&.Mui-selected': { backgroundColor: 'grey.200' } }}
                />

                {isAdmin && (
                  <Tab
                    label="Accounting"
                    {...a11yProps(3)}
                    sx={{ alignItems: 'start', '&.Mui-selected': { backgroundColor: 'grey.200' } }}
                  />
                )}
              </Tabs>
              <TabPanel value={tabIndex} index={0}>
                {!isAdmin && (
                  <EventSubmissionAlert
                    agencyId={agencyId}
                    event={nextEvent}
                    approvalStatus={nextEventApproval?.approvalStatus}
                  />
                )}
                <AgencyInfo
                  countiesServed={countiesServed}
                  categories={categories}
                  updateCountiesServed={setCountiesServed}
                />
                <AgencyContacts />
                <Grid item xs={12}>
                  <Typography variant="h4" component="p" color="primary.main">
                    Logo
                  </Typography>
                  <ImagePreview
                    filePath={filePreview}
                    sx={{ border: '1px solid black', maxWidth: 'fit-content' }}
                  />
                  <FileUpload
                    onFileSelect={onFileSelect}
                    label="Upload Logo"
                    accept="image/*"
                    name="logoFile"
                  />
                </Grid>
                <AgencySocialMedia />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <AgencyApprovalHistory
                  agencyId={agencyId}
                  nextEvent={nextEvent}
                  events={events?.items ?? []}
                  isActive={!agencyDetails.agency?.deactivatedDate}
                  isAdmin={isAdmin}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <Typography variant="h5" component="h2" color="primary.main">
                  Donations
                </Typography>
                <Typography variant="body2" mb={2}>
                  For net payment to your agency, download the report and reference Column L (Amount
                  to Agency).
                </Typography>
                <AgencyDonationsList agencyId={agencyId} events={events?.items ?? []} />
              </TabPanel>
              <TabPanel value={tabIndex} index={3}>
                {isAdmin && <AgencyAccounting />}
              </TabPanel>
            </Stack>
          </Container>
        </Form>
      )}
    </Box>
  );
};

const categories = Object.values(AgencyCategory).map(item => {
  return { id: item, item: item.toString() } as DropDownItem;
});
