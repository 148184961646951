import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  Stack,
  Tooltip,
  Typography,
  MenuItem,
  Grid,
  Collapse,
  styled,
} from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { DataGrid, GridColDef, GridPaginationModel, GridSortItem } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';

import {
  AgencyApprovalStatus,
  AgencyCategory,
  AgencyListAgenciesAgencyList,
  AgencyListAgenciesAgencyListItem,
  AgencyQuerySortProperty,
  ModelsFundraisingEventModel,
  ModelsOrderByDirection,
  exportAgencies,
  exportContacts,
  useListAgencies,
} from '../../api/agency-api';
import { icons } from '../../shared/icons';
import { TextInput } from '../../shared/forms';
import { useDebouncedValue } from '../../shared/utils';
import { ButtonLink, Link } from '../../shared/components';
import downloadFile from '../../shared/utils/downloadFile';
import { useNextEvent } from '../../shared/events';

interface AgencyFiltersForm {
  search?: string;
  taxId?: string;
  displayName?: string;
  legalName?: string;
  county?: string;
  category?: AgencyCategory;
  bookId?: string;
  andarId?: string;
  isDonor?: boolean;
  isFederatedFund?: boolean;
  eftReceived?: boolean;
  hasMatchingGrant?: boolean;
  eventId?: string;
  approvalStatus?: AgencyApprovalStatus;
  isActive?: boolean;

  pagination: GridPaginationModel;
  orderBy: GridSortItem[];
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
interface Props {
  fundraisingEvents: ModelsFundraisingEventModel[];
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  color: 'common.white',
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const sortFieldMap = new Map<string, AgencyQuerySortProperty>([
  ['displayName', AgencyQuerySortProperty.Name],
  ['legalName', AgencyQuerySortProperty.LegalName],
  ['taxId', AgencyQuerySortProperty.TaxId],
]);

export const AgencyManagementList: React.FC<Props> = ({ fundraisingEvents }: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  const nextEvent = useNextEvent();
  const form = useForm<AgencyFiltersForm>({
    defaultValues: {
      eventId: nextEvent?.id,
      isActive: true,
      pagination: { page: 0, pageSize: 10 },
      orderBy: [{ field: 'displayName', sort: 'asc' }],
    },
  });

  const {
    search,
    taxId,
    displayName,
    legalName,
    county,
    category,
    bookId,
    andarId,
    isDonor,
    isFederatedFund,
    eftReceived,
    hasMatchingGrant,
    eventId,
    approvalStatus,
    isActive,
    pagination,
    orderBy,
  } = form.watch();

  const params = useDebouncedValue(
    () => ({
      search,
      taxId,
      displayName,
      legalName,
      county,
      category,
      bookId,
      andarId,
      isDonor,
      isFederatedFund,
      eftReceived,
      hasMatchingGrant,
      eventId,
      approvalStatus,
      isActive,
      orderBy: !!orderBy.length ? sortFieldMap.get(orderBy[0].field) : undefined,
      orderByDirection: !!orderBy.length
        ? orderBy[0].sort === 'desc'
          ? ModelsOrderByDirection.Descending
          : ModelsOrderByDirection.Ascending
        : undefined,
      ...pagination,
    }),
    500,
    [
      search,
      taxId,
      displayName,
      legalName,
      county,
      category,
      bookId,
      andarId,
      isDonor,
      isFederatedFund,
      eftReceived,
      hasMatchingGrant,
      eventId,
      approvalStatus,
      isActive,
      pagination,
      orderBy,
    ]
  );

  const [gridData, setGridData] = useState<AgencyListAgenciesAgencyList>();
  const { data, isPending } = useListAgencies(params);

  // only update the gridData after the server responds so that the list doesn't flicker.
  useEffect(() => {
    if (!isPending) setGridData(data);
  }, [data, isPending]);

  return (
    <>
      <FormProvider {...form}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ backgroundColor: 'grey.200' }}
        >
          <Stack direction="row" alignItems="center" px={1}>
            <TextInput
              name="search"
              variant="outlined"
              placeholder="Search"
              sx={{ marginBottom: '0' }}
            />
            <Typography
              mb={0}
              ml={1}
              color="grey.600"
              variant="body2"
              display={{ xs: 'none', sm: 'flex' }}
            >
              FILTERS
            </Typography>
            <ExpandMore
              expand={expanded}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
              sx={{ color: 'grey.600' }}
            >
              <FontAwesomeIcon icon={icons.chevronDown} color="grey.100" />
            </ExpandMore>
          </Stack>

          <Stack direction="row" sx={{ backgroundColor: 'grey.600' }}>
            <Tooltip title="Add Agency" arrow placement="bottom">
              <ButtonLink
                to="/agency/registration"
                sx={{
                  color: 'grey.100',
                  padding: '1rem',
                  borderRight: 'solid thin',
                  borderColor: 'grey.200',
                }}
              >
                <FontAwesomeIcon icon={icons.add} size="2x" />
              </ButtonLink>
            </Tooltip>
            <Tooltip title="Export Agency List" arrow placement="bottom">
              <Button
                sx={{
                  color: 'grey.100',
                  padding: '1rem',
                  borderRight: 'solid thin',
                  borderColor: 'grey.200',
                }}
                onClick={() => downloadFile('Agency-Export.csv', exportAgencies(params))}
              >
                <FontAwesomeIcon icon={icons.download} size="2x" />
              </Button>
            </Tooltip>
            <Tooltip title="Export Agency Contacts" arrow placement="bottom-start">
              <Button
                sx={{ color: 'common.white', padding: '1rem' }}
                onClick={() => downloadFile('Agency-Contacts-Export.csv', exportContacts(params))}
              >
                <FontAwesomeIcon icon={icons.team} size="2x" />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ backgroundColor: 'grey.100' }}>
          <Grid container spacing={2} p={4}>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="taxId" label="Tax ID" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="displayName" label="Name" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="legalName" label="Legal Name" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="bookId" label="Book ID" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="andarId" label="ANDAR #" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="county" label="County" select variant="outlined">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Genesee">Genesee</MenuItem>
                <MenuItem value="Livingston">Livingston</MenuItem>
                <MenuItem value="Monroe">Monroe</MenuItem>
                <MenuItem value="Ontario">Ontario</MenuItem>
                <MenuItem value="Orleans">Orleans</MenuItem>
                <MenuItem value="Seneca">Seneca</MenuItem>
                <MenuItem value="Wayne">Wayne</MenuItem>
                <MenuItem value="Wyoming">Wyoming</MenuItem>
                <MenuItem value="Yates">Yates</MenuItem>
              </TextInput>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="category" label="Category" select variant="outlined">
                <MenuItem>&nbsp;</MenuItem>
                {Object.values(AgencyCategory).map(x => (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </TextInput>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="approvalStatus" label="Approval Status" select variant="outlined">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(AgencyApprovalStatus).map(x => (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </TextInput>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="isDonor" label="Is Direct Donor?" select variant="outlined">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextInput>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput
                name="isFederatedFund"
                label="Is Federated Fund?"
                select
                variant="outlined"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextInput>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput
                name="hasMatchingGrant"
                label="Has Matching Grant?"
                select
                variant="outlined"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextInput>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="isActive" label="Active" select variant="outlined">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextInput>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextInput name="eftReceived" label="EFT received" variant="outlined" select>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextInput>
            </Grid>
            {fundraisingEvents && (
              <Grid item xs={12} sm={4} md={3}>
                <TextInput name="eventId" label="Event" variant="outlined" select>
                  {fundraisingEvents.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextInput>
              </Grid>
            )}
          </Grid>
        </Collapse>
      </FormProvider>

      {gridData && (
        <DataGrid
          autoHeight
          rows={gridData?.items ?? []}
          rowCount={gridData?.totalCount}
          columns={columns}
          pagination
          paginationMode="server"
          paginationModel={pagination}
          pageSizeOptions={[5, 10, 25, 50]}
          onPaginationModelChange={x => form.setValue('pagination', x)}
          sortingMode="server"
          sortModel={orderBy}
          onSortModelChange={x => form.setValue('orderBy', x)}
          sx={{ width: '100%', maxWidth: '100%', border: '0', display: 'grid' }}
        />
      )}
    </>
  );
};

const columns: GridColDef<AgencyListAgenciesAgencyListItem>[] = [
  {
    field: 'taxId',
    headerName: 'Tax ID',
    width: 150,
    sortable: true,
    editable: false,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`../agencies/${row.id}`}>
        {row.taxId}
      </Link>
    ),
    disableColumnMenu: true,
  },
  {
    field: 'displayName',
    headerName: 'Name',
    width: 200,
    sortable: true,
    editable: false,
    disableColumnMenu: true,
  },
  {
    field: 'categories',
    headerName: 'Categories',
    valueGetter: ({ row }) =>
      [row.primaryCategory, row.secondaryCategory].filter(x => !!x).join(', '),
    width: 200,
    sortable: false,
    editable: false,
    disableColumnMenu: true,
  },
  {
    field: 'counties',
    headerName: 'Counties Served',
    valueGetter: ({ row }) => row.counties.join(', '),
    flex: 3,
    sortable: false,
    editable: false,
    disableColumnMenu: true,
  },
  {
    field: 'mailingAddress.city',
    headerName: 'City',
    valueGetter: ({ row }) => row.mailingAddress.city,
    flex: 1,
    sortable: false,
    editable: false,
    disableColumnMenu: true,
  },
  {
    field: 'approvalStatus',
    headerName: 'Approval Status',
    flex: 3,
    sortable: false,
    editable: false,
    valueGetter: ({ row }) => row.approvalStatus ?? 'N/A',
    disableColumnMenu: true,
  },
  {
    field: 'isActive',
    headerName: 'Active Status',
    flex: 2,
    sortable: false,
    editable: false,
    valueGetter: ({ row }) => (row.isActive ? 'Active' : 'Deactivated'),
    disableColumnMenu: true,
  },
];
