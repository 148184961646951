import React from 'react';
import { Backdrop, Box, Fade, Grid, Modal, Stack, Typography, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDonateModalQuery, DonateModal } from '../../../queries/useDonateModalQuery';
import { Image, VisualContent } from '../../components';
import { withBoundary } from '../../withBoundary';
import { icons } from '../../icons';

import './donateModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'grey.200',
  boxShadow: 24,
  p: 0,
  maxWidth: '900px',
  maxHeight: '90vh',
  overflow: 'scroll',
};

const BlockImage = styled(Image)({
  display: 'inline-block',
  height: 'auto',
  width: '100%',
});

const DonateModalComponent: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data } = useDonateModalQuery();

  if (!data?.attributes.enabled) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: { xs: 'auto', md: '6rem' },
        marginBottom: '0',
        justifyContent: 'center',
      }}
      mx={2}
    >
      <Box
        onClick={handleOpen}
        sx={{
          backgroundColor: 'primary.main',
          borderRadius: '1.5rem',
          overflow: 'hidden',
          border: 'solid medium',
          borderColor: 'primary.light',
        }}
      >
        <Stack
          className="ripple"
          direction="row"
          spacing={1}
          sx={{
            color: 'common.white',
            typography: 'h6',
            marginBottom: '0',
            cursor: 'pointer',
            padding: '.5rem 1rem',
            display: 'flex',

            alignItems: 'center',
            fontSize: { xs: '1.5rem', sm: '1rem' },
          }}
        >
          <FontAwesomeIcon icon={icons.donate} />
          <Box
            sx={{ display: { xs: 'none', sm: 'flex', marginBottom: '0', padding: '0 .125rem' } }}
          >
            Donate
          </Box>
        </Stack>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              onClick={handleClose}
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                top: '0',
                right: '0',
                color: 'common.white',
                backgroundColor: 'grey.500',
                fontSize: { xs: '1rem', sm: '1.5rem' },
                borderRadius: '0 0 0 100%',
                width: { xs: '40px', sm: '50px' },
                height: { xs: '40px', sm: '50px' },
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'start',
                padding: '.5rem .5rem 0 0',
              }}
            >
              <FontAwesomeIcon icon={icons.close} />
            </Box>
            <DonateModalContentBoundary content={data.attributes} />
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

interface DonateModalContentProps {
  content: DonateModal;
}

const DonateModalContent: React.FC<DonateModalContentProps> = ({ content }) => (
  <Grid container spacing={0} direction={{ xs: 'column-reverse', md: 'row' }}>
    <Grid item xs={12} sm={6}>
      <Box sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
        {!!content.bannerImage.data?.attributes && (
          <BlockImage src={content.bannerImage.data.attributes} alt="Donate today" />
        )}
        <Box p={4}>
          <VisualContent data={content.leftContent} />
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12} sm={6} p={4} sx={{ backgroundColor: 'common.white' }}>
      <Typography variant="h5" component="h2">
        Donate today
      </Typography>
      <VisualContent data={content.rightContent} />
    </Grid>
  </Grid>
);

const DonateModalContentBoundary = withBoundary(DonateModalContent);

export { DonateModalComponent as DonateModal };
